import React from 'react';
import { store, persistor } from './store';
import AppRouter from './AppRouter';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { ToastProvider } from './components/Toast';
import { PersistGate } from 'redux-persist/integration/react'
import { ZoneMinderProvider } from './context/ZoneMinderContext';

const App: React.FC = () => {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ToastProvider>
                    <ZoneMinderProvider>
					    <AppRouter />
                    </ZoneMinderProvider>
				</ToastProvider>
				<ToastContainer />
			</PersistGate>
		</Provider>
	);
};

export default App;
