import { css } from '@emotion/css';
import LoadingPage from 'app/components/LoadingPage';
import RowStatus from 'app/components/RowStatus';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import { useGetPanelDataQuery } from 'app/services/PanelService';
import { PanelProps } from 'app/types/Panel'
import { getColor, getColorByName } from 'app/utils';
import { formattedValueToString, getValueFormat } from 'app/valueFormats';
import moment from 'moment';
import { ColumnProps } from 'primereact/column';
import React, { useCallback, useMemo } from 'react'
import _ from 'lodash';

export interface CustomCulumnProps extends ColumnProps {
	bodyTemplate?: string;
	unit?: moment.unitOfTime.DurationConstructor | string | any;
	[key: string]: any;
}

export interface TritronikOverviewOptions {
	title?: string;
	columns: CustomCulumnProps[];
	splitColumn: number;
}

export interface TritronikOverviewPanelProps extends PanelProps<TritronikOverviewOptions> {

}

const TritronikOverviewPanel = (props: TritronikOverviewPanelProps) => {
	const { panel, refreshInterval, width, height, options } = props;
	const styles = createStyles(width, height);

	const params = usePanelDataParams();

	const { data: rawData, isLoading } = useGetPanelDataQuery({ ...params, id: panel.id, modelId: params.id || undefined }, { skip: !panel, pollingInterval: refreshInterval });

	const renderBodyTemplate = useCallback((template: string, rowData: any, opts: CustomCulumnProps) => {

		if (rowData === null || !rowData[opts.field!] || rowData[opts.field!] === null || rowData[opts.field!] === '') {
			return '-';
		}
		const unit = opts.unit || 'none';
		const steps = opts.thresholds?.steps || [{ value: 0, color: "#000" }];

		const unitValueTemplate = () => {
			const value = (opts.field && rowData[opts.field] !== undefined) ? rowData[opts.field] : 0;
			const formatV = getValueFormat(unit)(value);

			const valueString = unit === 'ohm' ? `${value?.toFixed(2)} ohm` : formattedValueToString(formatV);
			const bodyStyle = css`
				color: ${getColor(value, steps)};
			`;
			return <span className={bodyStyle}>{`${valueString}`}</span>;
		}

		const durationTimeTemplate = () => {
			const value = (opts.field && rowData[opts.field] !== undefined) ? rowData[opts.field] : 0;
			const durr = moment.duration(value, opts.unit || 'ms');
			const d = durr.days();
			const h = durr.hours();
			const m = durr.minutes();

			const bodyStyle = css`
				color: ${getColor(h, steps)};
			`;

			return <span className={bodyStyle}>{`${d > 0 ? d+"d " : ""}${h}h ${m}m`}</span>;
		}

		const dateFormatTemplate = () => {
			const value = (opts.field && rowData[opts.field] !== undefined) ? rowData[opts.field] : 0;
			const v = getValueFormat(unit)(value);
			return <span>{formattedValueToString(v)}</span>
		}

		const statusTextTemplate = () => {
			const value = (opts.field && rowData[opts.field] !== undefined) ? rowData[opts.field] : '-';
			const getColorStatus = () => {
				if (!opts.mappings) return "#000";

				const mapping = opts.mappings.find((m) => _.toLower(m.value) === value);

				return mapping ? getColorByName(mapping.color) : '#000';
			}
			const bodyStyle = css`
				color: ${getColorStatus()};
				text-transform: capitalize;
			`;

			return (
				<span className={bodyStyle}>{value}</span>
			)
		}

		switch (template) {
			case 'unitValueTemplate': return unitValueTemplate();
			case 'durationTimeTemplate': return durationTimeTemplate();
			case 'dateFormatTemplate': return dateFormatTemplate();
			case 'statusTextTemplate': return statusTextTemplate();

			default:
				return opts.field ? rowData[opts.field] : '-';
		}
	}, []);

	const renderColumn = (data: any, column: CustomCulumnProps) => {
		if (data === null) return <span style={column.style}>{'-'}</span>
		return <span style={column.style}>{data[column.field!] || '-'}</span>
	}

	const rows = useMemo(() => {
		const dataRows: any[] = [];

		if (rawData && rawData.responseDataValue && Array.isArray(rawData.responseDataValue) && rawData.responseDataValue.length > 0) {
			const columns = options.columns || [];
			const columnsLength = columns.length;
			const splitColumn = options?.splitColumn || 1;
			const chunkSize = Math.round(columnsLength / splitColumn);

			const chunkColumns = _.chunk(columns, chunkSize);

			rawData.responseDataValue.forEach((d) => {
				const dataColumns: any[] = [];

				chunkColumns.forEach((column, i) => {
					const dataCol = column.map(col => {
						const value = col.bodyTemplate ? renderBodyTemplate(col.bodyTemplate, d, col) : renderColumn(d, col);
						return { label: col.header, value: value && value !== '' ? value : '-' };
					})
					dataColumns.push(dataCol);
				});

				dataRows.push(dataColumns);
			});
		}

		return dataRows;
	}, [rawData, options, renderBodyTemplate]);

	const renderRow = (rows: any[]) => {
		return rows && rows.length > 0 && rows.map((r, i) => {
			return <RowStatus key={`row-panel-${i}`} stripped={i % 2 === 0} label={r.label} value={r.value} />
		})
	}

	if (isLoading) {
		return <LoadingPage />;
	}

	return (
		<div className={styles.wrapper}>
			{options.title && <h3>{options.title}</h3>}

			{rows && rows.length > 0 && rows.map((row, i) => (
				<div key={`row-${i}`} className="row">
					{row.map((col, j) => (
						<div key={`col-${j}`} className="col">
							{renderRow(col)}
						</div>
					))}
				</div>
			))}
		</div>
	)
}

const createStyles = (width: number, height: number) => {
	return {
		wrapper: css`
			height: 100%;
			width: 100%;
			overflow: auto;
			padding: 1.25rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
		`,
	}
}

export default TritronikOverviewPanel
