import { selectDateTime, setRefreshInterval, updateDateTimeRange } from 'app/reducers/appSlice';
import { selectPageById, setIsEditingPage } from 'app/reducers/pageSlice';
import { useAppDispatch, useAppSelector } from 'app/store';
import { BreadCrumb } from 'primereact/breadcrumb';
import React, { useCallback, useMemo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import moment, { Moment } from 'moment';
import DateRangePicker from 'components/molecules/DateRangePicker/DateRangePicker';
import { RefreshPicker } from 'components';
import { useGetPanelsQuery, panelApi } from 'app/services/PanelService';
import { usePanelDataParams } from 'app/hooks/usePanelDataParams';
import * as rangeUtil from '../../utils/datetime/rangeutil';
import './style.css';
import Restricted from '../Restricted';
import { setIsEditing } from 'app/reducers/updateGridSlice';
import { locationService } from 'app/services/LocationService';

type Props = {
	match: any;
	handleSave?: () => void;
};

const PageHeader: React.FC<Props> = ({ match, handleSave }: Props) => {
	const pageId: any = match.params.uid;

	const isEditing = useAppSelector((state) => state.updateGrid.isEditing);

	const defaultPage = useAppSelector((state) => state.page.defaultPage);
	const currentPage = useAppSelector((state) => selectPageById(state, pageId));
	const isRefreshing = useAppSelector((state) => state.application.isRefreshing);
	const { beginTs, endTs } = useAppSelector(selectDateTime);

	const { data: panels } = useGetPanelsQuery({ pageId: pageId }, { skip: !pageId });

	const projectId = useAppSelector(state => state.application.ids[0])

	const panelParams = usePanelDataParams();

	const items = useMemo(() => {
		if (!currentPage) return [];

		return [{ label: currentPage.name }];
	}, [currentPage]);

	const home = { icon: 'pi pi-home', url: defaultPage };

	const dispatch = useAppDispatch();

	const onFilterDateChange = (start: Moment, end: Moment) => {
		dispatch(
			updateDateTimeRange({
				beginTs: start.valueOf(),
				endTs: end.valueOf(),
			}),
		);
	};

	const panelIds = useMemo(() => {
		return panels && panels.content && panels.content.length > 0 ? panels.content.map((panel) => panel.id) : [];
	}, [panels]);

	const onRefresh = useCallback(() => {
		panelIds.forEach((panelId) => {
			dispatch(panelApi.endpoints.getPanelData.initiate({ id: panelId, ...panelParams }, { forceRefetch: true }));
		});
	}, [dispatch, panelIds, panelParams]);

	const onRefreshIntervalChanged = (interval: string) => {
		if (interval === '') {
			dispatch(setRefreshInterval(0));
		} else {
			const intervalMs = rangeUtil.intervalToMs(interval);
			dispatch(setRefreshInterval(intervalMs));
		}
	};

	const history = locationService.getHistory();

	const navigateAddPanel = () => {
		history.push({ pathname: `/dashboard-management/add-panel/${pageId}` });
	};

	const isEditingPage = useAppSelector(state => state.page.isEditingPage)

	const navigateEditPage = () => {
		history.push({ pathname: `/page-management/edit-menuPage/${projectId}/${pageId}` });
		dispatch(setIsEditingPage(!isEditingPage))
	};

	

	return (
		<>
			<Container fluid className="mt-3">
				<div className="page-container">
					<Row className="mt-3 d-flex justify-content-between">
						<Col className="bread-container d-flex align-items-center">
							<BreadCrumb model={items} home={home} />
						</Col>
						<Row className="d-flex justify-content-end btn-container-1">
							<Restricted to={'UPDATE DASHBOARD'}>
							{isEditing ? (
								<>
									<button className="btn btn-add" type="button" onClick={navigateAddPanel}>
										<i className="fas fa-plus-circle"></i>Add Panel
									</button>
									<button className="btn btn-save activated" type="button" onClick={handleSave}>
										<i className="far fa-save"></i>Save
									</button>
									<button
										className="btn btn-cancel"
										type="button"
										onClick={() => dispatch(setIsEditing(!isEditing))}
									>
										<i className="fas fa-times"></i>Cancel
									</button>
								</>
							) : (
								<>
								<button className="btn btn-edit activated" type='button' onClick={navigateEditPage}>
									<i style={{ marginRight: '0.5rem' }} className="fas fa-wrench"></i>Edit Page
								</button>
								<button
									className="btn btn-edit activated"
									type="button"
									onClick={() => dispatch(setIsEditing(!isEditing))}
								>
									<i style={{ marginRight: '0.5rem' }} className="fas fa-pencil-alt"></i>Edit Panel
								</button>
								</>
							)}
							</Restricted>
							<span className="btn btn-date">
								<DateRangePicker
									onChange={onFilterDateChange}
									startDate={moment(beginTs)}
									endDate={moment(endTs)}
									format="ll"
									className="date-ranger"
								/>
							</span>
							<span className="btn btn-refresh">
								<RefreshPicker
									onRefresh={onRefresh}
									isLoading={Boolean(isRefreshing)}
									onIntervalChanged={onRefreshIntervalChanged}
									className="refresh-pickers"
								/>
							</span>
						</Row>
					</Row>
				</div>
			</Container>
		</>
	);
};

export default PageHeader;
