import { getValueFormat } from 'app/valueFormats';

export const formatValue = (value: number, unit: string, decimals: number = 0) => {
    const formattedValue = getValueFormat(unit)(value, decimals);
    const text = new Intl.NumberFormat('id-ID', {
        maximumFractionDigits: 2,
    }).format(Number(formattedValue.text));
    return `${formattedValue.prefix ?? ''}${text}${formattedValue.suffix ?? ''}`;
};

export function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? ' h, ' : ' h, ') : '';
    var mDisplay = m > 0 ? m + (m === 1 ? ' m, ' : ' m, ') : '';
    var sDisplay = s > 0 ? s + (s === 1 ? ' s' : ' s') : '';
    return hDisplay + mDisplay + sDisplay;
}

export function getDuration(millis) {
    var dur = {};
    var units = [
        { label: 'millis', mod: 1000 },
        { label: 'seconds', mod: 60 },
        { label: 'minutes', mod: 60 },
        { label: 'hours', mod: 24 },
        { label: 'days', mod: 31 },
    ];
    // calculate the individual unit values...
    units.forEach(function (u) {
        millis = (millis - (dur[u.label] = millis % u.mod)) / u.mod;
    });
    // convert object to a string representation...
    var nonZero = function (u) {
        return dur[u.label];
    };
    dur.toString = function () {
        return units
            .reverse()
            .filter(nonZero)
            .map(function (u) {
                return dur[u.label] + ' ' + (dur[u.label] == 1 ? u.label.slice(0, -1) : u.label);
            })
            .join(', ');
    };
    return dur;
}
