import { projectApi } from './../services/ProjectService';
import { pageApi } from './../services/PageService';
import { panelApi } from './../services/PanelService';
import { alertConfigurationApi } from 'app/features/AlertConfiguration/services/alertApi';
import { authApi } from 'app/features/auth/authAPI';
import { unauthenticatedMiddleware } from './middlewares/unauthenticatedMiddleware';
import { abnormalEventApi } from './../features/AbnormalEvent/services/abnormalEventAPI';
import { tenantConfigurationApi } from './../features/Tenant/services/tenantApi';
import { sensorConfigurationApi } from 'app/features/SensorProperty/services/sensorApi';
import { userConfigurationApi } from 'app/features/UserManagement/services/userApi';
import { apiConfigurationApi } from 'app/features/ApiManagement/services/apiManagementApi';
import { roleConfigurationApi } from 'app/features/RoleManagement/services/roleApi';
import { explorationApi } from 'app/features/DataExploration/services/dataExplorationApi';
import { siteManagementApi } from 'app/features/SiteManagement/services/siteApi';
import { zmApi } from 'app/features/ZoneMinder/zmApi';

const middleware = [
	projectApi.middleware,
	pageApi.middleware,
	panelApi.middleware,
	roleConfigurationApi.middleware,
	apiConfigurationApi.middleware,
	userConfigurationApi.middleware,
	alertConfigurationApi.middleware,
	tenantConfigurationApi.middleware,
	sensorConfigurationApi.middleware,
	authApi.middleware,
	abnormalEventApi.middleware,
	explorationApi.middleware,
	siteManagementApi.middleware,
    zmApi.middleware,
	unauthenticatedMiddleware,
];

export default middleware;
