import { FieldConfigSource } from 'app/types'
import { Panel as PanelModel } from 'app/types/Panel'
import React from 'react'
import Panel, { PanelProps } from './Panel'

interface Props {
  width: number;
  height: number;
  panel?: PanelModel;
  component?: any;
  key?:any
}

const DashboardPanel = (props: Props) => {

  const { panel, component, key } = props
  const fieldConfig: FieldConfigSource = panel?.componentConfig && panel.componentConfig !== '{}' && panel.componentConfig !== 'null' ? JSON.parse(panel.componentConfig) : {};

  const restProps = React.useMemo<Partial<PanelProps>>(() => {
	const hasTitle = Boolean(panel?.title && (panel.title !== '' && panel.title !== null));
	const noPadding = fieldConfig.options && fieldConfig.options.hasOwnProperty('noPadding') ? Boolean(fieldConfig.options?.noPadding) : true;

	return {
		hasTitle: hasTitle,
		noPadding: noPadding,
		title: panel?.title
	}
  }, [panel, fieldConfig])

  return (
    <div className="panel" key={key}>
      <Panel
        key={key}
        {...props}
        {...restProps}
		links={fieldConfig.links}
        panel={component}
		panelModel={panel}
      />
    </div>
  )
}

export default DashboardPanel
