import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';

import { API_URL, API_URL_ALERT, API_URL_SITE, ZM_API_WRAPPER_URL } from './../../config/config';
import { RootState } from 'app/store';

export const baseQuery = fetchBaseQuery({
	baseUrl: API_URL,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).auth.access_token;
		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}
		return headers;
	},
});

export const baseQueryAlert = fetchBaseQuery({
	baseUrl: API_URL_ALERT,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).auth.access_token;
		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}
		return headers;
	},
});

export const baseQuerySite = fetchBaseQuery({
	baseUrl: API_URL_SITE,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).auth.access_token;
		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}
		return headers;
	},
});

export const baseQueryZM = fetchBaseQuery({
	baseUrl: ZM_API_WRAPPER_URL,
});
