import { PanelProps } from 'app/types/Panel'
import React from 'react'
import { TritronikCCTVPanel } from '../TritronikCCTVPanel'

export interface TritronikDevPanelProps extends PanelProps {
}

const TritronikDevPanel = (props: TritronikDevPanelProps) => {

	return <TritronikCCTVPanel {...props} />
}

export default TritronikDevPanel
