import { css } from '@emotion/css';
import { ThresholdsConfig } from 'app/types';
import { calculateFontSize, getColor } from 'app/utils';
import React from 'react'
import classNames from 'classnames';

export type BatteryStatus = 'floating charge' | 'equal charge' | 'discharge' | 'rest' | 'abnormal' | 'error' | string | undefined;
export interface BatteryProps {
	width: number;
	height: number;
	percentage: number;
	thresholds?: ThresholdsConfig;
	isCharging?: boolean;
	isAbnormal?: boolean;
	isError?: boolean;
}

const Battery = (props: BatteryProps) => {
	const { width, height, percentage, thresholds, isCharging, isAbnormal, isError } = props;

	const batteryHeight = height * 0.6 < 100 ? 100 : height * 0.6;
	const batteryWidth = batteryHeight * 0.6;
	const batteryHeadHeight = batteryHeight * 0.05
	const batteryBorderWidth = (batteryWidth * 0.025) <= 2 ? 2 : batteryWidth * 0.025;

	const color = thresholds ? getColor(percentage, thresholds?.steps) : '#73bf69';

	const wrapper = css`
		display: flex;
		flex-direction: row;
	`;

	const batteryStyle = css`
		border: ${batteryBorderWidth}px solid ${color};
		width: ${batteryWidth}px;
		height: ${batteryHeight}px;
		border-radius: 8px;
		position: relative;

		&:before {
			content: "";
			height: ${batteryHeadHeight}px;
			width: ${batteryWidth * 0.5}px;
			background: ${color};
			display: block;
			position: absolute;
			top: -${batteryHeadHeight}px;
			left: 25%;
			border-radius: 12px 12px 0 0;
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			border: ${batteryBorderWidth}px solid #ffffff;
			border-radius: 8px;
		}
	`;

	const batteryLevelStyle = css`
		background: ${color};
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0px;
		left: 0;
		right: 0;
		border-radius: 8px;

		&.warn {
			background-color: #FF9830;
			padding: 0;
		}

		&.alert {
			background-color: #F2495C;
			padding: 0;
		}
	`;

	const iconStyle = css`
		width: 100%;
		font-size: ${width * 0.05}px;
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		z-index: 100;
		color: #FADE2A;
	`;

	const chargingStateStyle = css`
		color: #FADE2A !important;
	`;

	const abnormalStateStyle = css`
		color: #FF9830 !important;
	`;

	const errorStateStyle = css`
		color: #F2495C !important;
	`;

	const batteryInfoStyle = css`
		margin-left: ${width * 0.025}px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;

		h1 {
			margin-bottom: ${height * 0.025}px;
			font-size: ${calculateFontSize(`${percentage}%`, width * 0.075, height * 0.075, 1)}px;
			color: ${color};
		}

		p {
			text-transform: uppercase;
		}
	`;

	return (
		<div className={wrapper}>
			<div className={`${batteryStyle}`}>
				<div className={`${batteryLevelStyle}`} style={{ height: `${percentage}%` }}></div>
				{isCharging && !isAbnormal && !isError && <i className={classNames("mdi mdi-lightning-bolt", iconStyle, chargingStateStyle)}></i>}
				{isAbnormal && !isError && <i className={classNames("mdi mdi-alert", iconStyle, abnormalStateStyle)}></i>}
				{isError && <i className={classNames("mdi mdi-alert-decagram", iconStyle, errorStateStyle)}></i>}
			</div>
			<div className={batteryInfoStyle}>
				<h1>{percentage}%</h1>
				<p>avg</p>
			</div>
		</div>
	)
}

export default Battery
