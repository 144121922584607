import { createSlice, PayloadAction, createEntityAdapter, createSelector } from '@reduxjs/toolkit'
import { panelApi } from 'app/services/PanelService'
import { RootState } from 'app/store'
import { SidebarMenu } from 'app/types/navigation'
import moment from 'moment'
import { projectApi } from './../services/ProjectService'
import { Project } from './../types'

type DateTimeRange = {
	beginTs: number;
	endTs: number;
}
export interface ApplicationState {
  selectedApplication: any;
  refreshInterval: number;
  isRefreshing: boolean;
  beginTs: number;
  endTs: number;
  mainPage?: string;
}

const appAdapter = createEntityAdapter<Project>()

const initialState = appAdapter.getInitialState<ApplicationState>({
  selectedApplication: null,
  refreshInterval: 0,
  isRefreshing: false,
  beginTs: moment().startOf('day').valueOf(),
  endTs: moment().endOf('day').valueOf(),
})

const appSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    selectApplication(state, action: PayloadAction<any>) {
      state.selectedApplication = action.payload
    },
    setRefreshInterval(state, action: PayloadAction<number>) {
      state.refreshInterval = action.payload
    },
	updateDateTimeRange(state, { payload }: PayloadAction<DateTimeRange>) {
		state.beginTs = payload.beginTs;
		state.endTs = payload.endTs;
	}
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        projectApi.endpoints.getProjects.matchFulfilled,
        (state, { payload }) => {
          if (payload.content && payload.content.length > 0) {
            appAdapter.setAll(state, payload.content);
            state.selectedApplication = payload.content[0].id;
          }
        }
      )
      .addMatcher(
        panelApi.endpoints.getPanelData.matchPending,
        (state, action) => {
          state.isRefreshing = true
        }
      )
      .addMatcher(
        panelApi.endpoints.getPanelData.matchFulfilled,
        (state, action) => {
          state.isRefreshing = false
        }
      )
      .addMatcher(
        panelApi.endpoints.getPanelData.matchRejected,
        (state, action) => {
          state.isRefreshing = false
        }
      )
  }
})

export const { selectApplication, setRefreshInterval, updateDateTimeRange } = appSlice.actions
export default appSlice.reducer

export const currentApplicationId = state => state.application.selectedApplication

export const {
  selectById: selectAppById,
  selectIds: selectAppIds,
  selectEntities: selectAppEntities,
  selectAll: selectAllApps,
  selectTotal: selectTotalApps,
} = appAdapter.getSelectors<RootState>((state) => state.application);

export const currentApplicationSelector = createSelector(
  [
    selectAppEntities,
    currentApplicationId,
  ],
  (entities, id) => {
    return entities[id]
  }
);

export const appRoutesSelector = createSelector(
  currentApplicationSelector,
  (app): SidebarMenu[] => {
    if (app && app.pages && app.pages.length > 0) {
      const sortedPages = app.pages.slice().sort((a,b):any => {
        if(a.pageOrder && b.pageOrder) return a.pageOrder - b.pageOrder
        return null
      })
      const routes: SidebarMenu[] = [];
      sortedPages.forEach((p) => {
		if (p.pageType === 'DASHBOARD') {
			routes.push({
			  path: `/d/${p.id}/${app.path}/${p.path}`,
			  name: p.name,
			  icon: p.iconName,
			  miniName: p.miniName,
			  invisible: !p.visibleInMenu,
        permission: p.permission,
        id:p.id,
        pageOrder:p.pageOrder
			});
		}

		if (p.pageType === 'RESOURCE') {
			routes.push({
				path: `/${p.path}`,
				name: p.name,
				icon: p.iconName,
				miniName: p.miniName,
				invisible: !p.visibleInMenu,
				feature: p.path,
        permission: p.permission,
        id:p.id,
        pageOrder:p.pageOrder
			});
		}
      });
      return routes;
    }
    return []
  }
)

export const selectDateTime = createSelector(
	(state: RootState) => state.application,
	({ beginTs, endTs }) => ({ beginTs, endTs })
);
