import Keycloak from 'keycloak-js';
declare global {
	interface Window {
		_env_: any;
	}
}

export const __DEV__: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const API_URL = window._env_.REACT_APP_API_URL || 'http://localhost';
export const API_URL_ALERT = window._env_.REACT_APP_API_URL_ALERT || 'http://localhost';
export const API_URL_SITE = window._env_.REACT_APP_API_URL_SITE || 'http://localhost';

export const keycloakConfig: Keycloak.KeycloakConfig = {
	url: window._env_.REACT_APP_KEYCLOAK_URL || 'http://localhost:8080/auth/',
	realm: window._env_.REACT_APP_KEYCLOAK_REALM || 'master',
	clientId: window._env_.REACT_APP_KEYCLOAK_CLIENT_ID || '',
};

export const APP_URL = window._env_.REACT_APP_WEB_URL || process.env.PUBLIC_URL;

export const APP_LOGO = window._env_.APP_LOGO;
export const APP_LOGIN_BG = window._env_.APP_LOGIN_BG;
export const APP_LOGIN_TITLE = window._env_.APP_LOGIN_TITLE;

export const search_debounce_time = 500;

export const PANEL_TITLE_FONT_SIZE = window._env_.PANEL_TITLE_FONT_SIZE || 14;

export const SHOW_LOGIN_FOOTER = window._env_.SHOW_LOGIN_FOOTER;

export const ZM_BASE_URL = window._env_.ZM_BASE_URL;
export const ZM_USERNAME = "admin";
export const ZM_PASSWORD = "tr1tr0n1k";
export const ZM_API_WRAPPER_URL = window._env_.ZM_API_WRAPPER_URL;
