/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { smartSolutionLogo } from 'assets';

import React from 'react';
// react library for routing
import { Redirect, Route, Switch } from 'react-router-dom';
// core components
import AppSidebar from './AppSidebar';
import AppNavbar from './AppNavbar';
import { getAppRoutes } from './../routes/routes';
import { RouteDescriptor, SidebarMenu } from 'app/types/navigation';
import AuthorizedFunction from './keycloak/AuthorizedFunction';
import TritronikRoute from './TritronikRoute';
import ProfilePage from 'app/features/auth/pages/Profile/ProfilePage';
import AbnormalEventListPage from 'app/features/AbnormalEvent/pages/AbnormalEventListPage';
import {
    AlertConfigurationPage,
    CreateAlertRulePage,
    EditAlertConfigurationPage,
} from 'app/features/AlertConfiguration/pages';
import { CreateTenant, EditTenant, TenantPage } from 'app/features/Tenant/pages';
import { EditSensorPage, SensorPropertyPage } from 'app/features/SensorProperty/pages';
import { CreateUserPage, EditUserPage, UserManagementPage } from 'app/features/UserManagement/pages';
import { ApiManagementPage, CreateApiPage, EditApi } from 'app/features/ApiManagement/pages';
import { CreateRolePage, EditRolePage, RoleManagementPage } from 'app/features/RoleManagement/pages';
import DataExploration from 'app/features/DataExploration';
import AddPanelPage from 'app/features/dashboard/containers/AddPanelPage';
import AddMenuPage from 'app/features/PageManagement/containers/AddMenuPage';
import ProjectConfigPage from 'app/features/ProjectConfiguration/containers/ProjectConfigPage';
import SiteManagementPage from 'app/features/SiteManagement/pages/SiteManagementPage';
import CreateSitePage from 'app/features/SiteManagement/pages/CreateSitePage';
import EditSitePage from 'app/features/SiteManagement/pages/EditSitePage';
import LoginConfigPage from 'app/features/LoginConfiguration/containers/LoginConfigPage';
import CCTVGridView from 'app/features/ZoneMinder/pages/CCTVGridView';
import MonitorDetailPage from 'app/features/ZoneMinder/pages/MonitorDetailPage';
import MonitorSitePage from 'app/features/ZoneMinder/pages/MonitorSitePage';

type AppLayoutProps = {
    menus: SidebarMenu[];
};

type AppLayoutState = {
    sidenavOpen: boolean;
};

class AppLayout extends React.Component<AppLayoutProps, AppLayoutState> {
    constructor(props) {
        super(props);

        this.state = {
            sidenavOpen: true,
        };
    }

    componentDidUpdate(e) {
        if (e.history.pathname !== e.location.pathname) {
            document.documentElement.scrollTop = 0;
            // @ts-ignore
            document.scrollingElement.scrollTop = 0;
            // @ts-ignore
            this.refs.mainContent.scrollTop = 0;
        }
    }

    renderRoute = (route: RouteDescriptor) => {
        const roles = route.roles ? route.roles() : [];

        return (
            <Route
                exact={route.exact === undefined ? true : route.exact}
                path={route.path}
                key={route.path}
                render={(props) => {
                    if (roles?.length) {
                        if (!AuthorizedFunction(roles)) {
                            return <Redirect to="/" />;
                        }
                    }

                    return <TritronikRoute {...props} route={route} />;
                }}
            />
        );
    };

    hasRoute = (routeName: string) => {
        return Boolean(this.props.menus.find((menu) => menu.feature === routeName));
    };

    // toggles collapse between mini sidenav and normal
    toggleSidenav = () => {
        if (document.body.classList.contains('g-sidenav-pinned')) {
            document.body.classList.remove('g-sidenav-pinned');
            document.body.classList.add('g-sidenav-hidden');
        } else {
            document.body.classList.add('g-sidenav-pinned');
            document.body.classList.remove('g-sidenav-hidden');
        }
        this.setState((currentState) => ({ sidenavOpen: !currentState.sidenavOpen }));
    };

    render() {
        return (
			<>
                <AppSidebar
                    {...this.props}
                    toggleSidenav={this.toggleSidenav}
                    sidenavOpen={this.state.sidenavOpen}
                    logo={{
                        innerLink: '/',
                        imgSrc: smartSolutionLogo,
                        imgAlt: '...',
                    }}
                />
                <div className="main-content" ref="mainContent">
                    <AppNavbar
                        {...this.props}
                        theme="dark"
                        toggleSidenav={this.toggleSidenav}
                        sidenavOpen={this.state.sidenavOpen}
                        brandText={'Smart Solutions'}
                    />
                    <Switch>
                        {getAppRoutes().map((r) => this.renderRoute(r))}
                        <Route path="/profile" component={ProfilePage} />
                        {this.hasRoute('abnormal-event') && (
                            <Route path="/abnormal-event" component={AbnormalEventListPage} />
                        )}

                        {this.hasRoute('role-management') && (
                            <Route path="/role-management">
                                <Switch>
                                    <Route exact path={`/role-management`} component={RoleManagementPage} />
                                    <Route path={`/role-management/new`} component={CreateRolePage} />
                                    <Route path={`/role-management/edit/:id`} component={EditRolePage} />
                                </Switch>
                            </Route>
                        )}

                        {this.hasRoute('endpoint-api-management') && (
                            <Route path="/endpoint-api-management">
                                <Switch>
                                    <Route exact path={`/endpoint-api-management`} component={ApiManagementPage} />
                                    <Route path={`/endpoint-api-management/new`} component={CreateApiPage} />
                                    <Route path={`/endpoint-api-management/edit/:id`} component={EditApi} />
                                </Switch>
                            </Route>
                        )}

                        {this.hasRoute('user-management') && (
                            <Route path="/user-management">
                                <Switch>
                                    <Route exact path={'/user-management'} component={UserManagementPage} />
                                    <Route path={`/user-management/new`} component={CreateUserPage} />
                                    <Route path={`/user-management/edit/:id`} component={EditUserPage} />
                                </Switch>
                            </Route>
                        )}

                        {this.hasRoute('sensor-property') && (
                            <Route path="/sensor-property">
                                <Switch>
                                    <Route exact path={`/sensor-property`} component={SensorPropertyPage} />
                                    <Route path={`/sensor-property/edit/:id`} component={EditSensorPage} />
                                </Switch>
                            </Route>
                        )}

                        {this.hasRoute('tenant-management') && (
                            <Route path="/tenant-management">
                                <Switch>
                                    <Route exact path={`/tenant-management`} component={TenantPage} />
                                    <Route path={`/tenant-management/new`} component={CreateTenant} />
                                    <Route path={`/tenant-management/edit/:id`} component={EditTenant} />
                                </Switch>
                            </Route>
                        )}

                        {this.hasRoute('alert-configuration') && (
                            <Route path="/alert-configuration">
                                <Switch>
                                    <Route exact path={`/alert-configuration`} component={AlertConfigurationPage} />
                                    <Route path={`/alert-configuration/new`} component={CreateAlertRulePage} />
                                    <Route
                                        path={`/alert-configuration/edit/:id`}
                                        component={EditAlertConfigurationPage}
                                    />
                                </Switch>
                            </Route>
                        )}
                        {this.hasRoute('site-management') && (
                            <Route path="/site-management">
                                <Switch>
                                    <Route exact path={`/site-management`} component={SiteManagementPage} />
                                    <Route path={`/site-management/new`} component={CreateSitePage} />
                                    <Route path={`/site-management/edit/:id`} component={EditSitePage} />
                                </Switch>
                            </Route>
                        )}

                        <Route path="/dashboard-management">
                            <Switch>
                                <Route path={`/dashboard-management/add-panel/:pageId`} component={AddPanelPage} />
                                <Route path={`/dashboard-management/edit-panel/:pageId/:panelId`} component={AddPanelPage} />
                            </Switch>
                        </Route>

                        <Route path="/page-management">
                            <Switch>
                                <Route path={`/page-management/add-menuPage/:projectId`} component={AddMenuPage} />
                                <Route path={`/page-management/edit-menuPage/:projectId/:pageId`} component={AddMenuPage} />
                            </Switch>
                        </Route>
                        <Route path="/project-configuration">
                            <Switch>
                                <Route path={`/project-configuration/:projectId`} component={ProjectConfigPage} />
                            </Switch>
                        </Route>
                        <Route path="/login-configuration">
                            <Switch>
                                <Route path={`/login-configuration/:projectId`} component={LoginConfigPage} />
                            </Switch>
                        </Route>

                        <Route path="/data-exploration">
                            <Switch>
                                {this.hasRoute('data-exploration/shms') && (
                                    <Route exact path="/data-exploration/shms" component={DataExploration} />
                                )}
                            </Switch>
                        </Route>

                        <Route path="/cctv">
                            <Switch>
                                <Route exact path="/cctv" component={CCTVGridView} />
                                <Route exact path="/cctv/live-view" component={CCTVGridView} />
                                <Route path="/cctv/camera/:monitorId" component={MonitorDetailPage} />
                                <Route exact path="/cctv/site" component={MonitorSitePage} />
                            </Switch>
                        </Route>
					</Switch>
				</div>
                    {this.state.sidenavOpen ? <div className="backdrop d-xl-none" onClick={this.toggleSidenav} /> : null}
			</>
                );
	}
}

                export default AppLayout;
