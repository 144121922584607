import React, { useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import BootstrapDateRangePicker from 'react-bootstrap-daterangepicker';
import classNames from 'classnames';

import 'bootstrap-daterangepicker/daterangepicker.css';
import './DateRangePicker.scss';
import { useEffect } from 'react';

export interface Props {
	onChange?: (start, end, label) => void;
	className?: string | undefined;
	format?: string | undefined;
	startDate?: Moment;
	endDate?: Moment;
}

const defaultProps: Props = {
	format: 'LL',
	startDate: moment(),
	endDate: moment(),
	onChange: () => {},
};

const DateRangePicker = (props: Props) => {
	const [startDate, setStartDate] = useState<Moment>(props.startDate || moment());
	const [endDate, setEndDate] = useState<Moment>(props.endDate || moment());

	useEffect(() => {
		if (props.startDate) {
			setStartDate(props.startDate);
		}

		if (props.endDate) {
			setEndDate(props.endDate);
		}

	}, [props.startDate, props.endDate]);

	const onCallback = (start, end, label) => {
		setStartDate(start);
		setEndDate(end);
		if (typeof props.onChange !== 'undefined' && typeof props.onChange === 'function') {
			props.onChange(start, end, label);
		}
	};

	const dateString = useMemo(() => {
		return `${startDate.format(props.format)} - ${endDate.format(props.format)}`;
	}, [startDate, endDate, props.format]);

	const dateRangeOptions = useMemo(() => {
		return {
			ranges: {
				Today: [moment().startOf('day'), moment().endOf('day')],
				Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
				'This Week': [moment().startOf('week'), moment().endOf('week')],
				'Previous Week': [
					moment().subtract(1, 'week').startOf('week'),
					moment().subtract(1, 'week').endOf('week'),
				],
				'This Month': [moment().startOf('month'), moment().endOf('month')],
				'Previous Month': [
					moment().subtract(1, 'month').startOf('month'),
					moment().subtract(1, 'month').endOf('month'),
				],
				'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment()],
				'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment()],
				'This Year': [moment().startOf('year'), moment().endOf('year')],
				'Previous Year': [
					moment().subtract(1, 'year').startOf('year'),
					moment().subtract(1, 'year').endOf('year'),
				],
			},
			locale: {
				format: 'DD/MM/YYY',
			},
			alwaysShowCalendars: true,
			startDate: props.startDate,
			endDate: props.endDate,
			cancelButtonClasses: 'p-button-outlined mx-2',
			applyButtonClasses: '',
			buttonClasses: 'p-button p-button-sm',
			timePicker: true,
			timePicker24Hour: true,
			timePickerSeconds: true,
			opens: 'left',
		};
	}, [props.startDate, props.endDate]);

	return (
		<BootstrapDateRangePicker initialSettings={{ ...dateRangeOptions }} onCallback={onCallback}>
			<div className={classNames('date-range-picker', props.className)}>
				<i className="fa fa-calendar"></i>
				<span className={props.className}>{dateString}</span>
				<b className="caret"></b>
			</div>
		</BootstrapDateRangePicker>
	);
};

DateRangePicker.defaultProps = defaultProps;

export default DateRangePicker;
