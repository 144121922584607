import { RootState } from 'app/store';
import { createSlice, PayloadAction, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { projectApi } from './../services/ProjectService';
import { Page } from './../types';
import { panelApi } from 'app/services/PanelService';
import { SidebarMenu } from 'app/types/navigation';

export interface PageState {
	selectedPage: any;
	defaultPage: string;
	pagePanels: object;
	pages:Page[]
	isEditingPage?:boolean
	isReordering?:boolean
	menus:SidebarMenu[]
}

const swapElements = (arr, i1, i2) => {
	
	let temp = arr[i1];
  
	arr[i1] = arr[i2];
  
	arr[i2] = temp;

	return arr
  }

const pageAdapter = createEntityAdapter<Page>();

const initialState = pageAdapter.getInitialState<PageState>({
	selectedPage: null,
	defaultPage: '/',
	pagePanels: {},
	pages:[],
	isEditingPage:false,
	isReordering:false,
	menus:[]
});

const pageSlice = createSlice({
	name: 'page',
	initialState,
	reducers: {
		selectPage(state, action: PayloadAction<any>) {
			state.selectedPage = action.payload;
		},
		setDefaultPage(state, action: PayloadAction<string>) {
			state.defaultPage = action.payload;
		},
		setIsEditingPage(state, action:PayloadAction<boolean>){
			state.isEditingPage = action.payload
		},
		setIsReordering(state, action:PayloadAction<boolean>){
			state.isReordering = action.payload
		},
		setHandleUp(state, {payload}) {
			const menus = payload.menus
			const currentPageOrder = menus[payload.key]
			const prevPageOrder = menus[payload.key-1] 
			

			const changedCurrentOrder = menus.map(order => {
				if(order.id === currentPageOrder.id){
					return {
						...order,
						pageOrder:order.pageOrder - 1
					}
				}
				return order
			})

			const changedPrevOrder = changedCurrentOrder.map(order => {
				if(order.id === prevPageOrder.id){
					return {
						...order,
						pageOrder:order.pageOrder + 1
					}
				}
				return order
			})

			const swaped = swapElements(changedPrevOrder, payload.key, payload.key-1)

		if(changedPrevOrder) state.menus = swaped
		},
		setHandleDown(state, {payload}) {
			const menus = payload.menus
			const currentPageOrder = menus[payload.key]
			const prevPageOrder = menus[payload.key+1] 
			

			const changedCurrentOrder = menus.map(order => {
				if(order.id === currentPageOrder.id){
					return {
						...order,
						pageOrder:order.pageOrder + 1
					}
				}
				return order
			})

			const changedPrevOrder = changedCurrentOrder.map(order => {
				if(order.id === prevPageOrder.id){
					return {
						...order,
						pageOrder:order.pageOrder - 1
					}
				}
				return order
			})

			const swaped = swapElements(changedPrevOrder, payload.key, payload.key+1)

		if(changedPrevOrder) state.menus = swaped
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(projectApi.endpoints.getProjects.matchFulfilled, (state, { payload }) => {
			if (payload.content && payload.content.length > 0) {
				const projects = payload.content;

				projects.forEach((project) => {
					if (project.pages && project.pages.length > 0) {
						project.pages.forEach((page) => {
							pageAdapter.addOne(state, page);
						});
						const { pages } = projects[0];
						const sortedPages = pages.slice().sort((a,b):any => {
							if(a.pageOrder && b.pageOrder) return a.pageOrder - b.pageOrder
							return null
						})
						if (sortedPages && sortedPages.length > 0) {
							const defaultPage = sortedPages[0];
							state.selectedPage = defaultPage.id;

							if (defaultPage.pageType === 'RESOURCE') {
								state.defaultPage = `/${defaultPage.path}`;
							}

							if (defaultPage.pageType === 'DASHBOARD') {
								state.defaultPage = `/d/${defaultPage.id}/${defaultPage.path}`;
							}
						}
					}
				});
			}
		});
		builder.addMatcher(panelApi.endpoints.getPanels.matchFulfilled, (state, action) => {
			const pageId = action.meta.arg.originalArgs.pageId;
			const panelIds = action.payload.content.map((p) => p.id);
			if (pageId) {
				state.pagePanels[pageId] = panelIds;
			}
		});
		builder.addMatcher(projectApi.endpoints.getProjects.matchFulfilled, (state, {payload}) => { // create menus data in redux state
			const pages = payload.content[0].pages
			const sortedPages = pages.slice().sort((a,b):any => {
				if(a.pageOrder && b.pageOrder) return a.pageOrder - b.pageOrder
				return null
			})
			const routes:SidebarMenu[] = []
			sortedPages.forEach(p => {
				if(p.pageType === 'DASHBOARD'){
					routes.push({
						path: `/d/${p.id}/${payload.content[0].path}/${p.path}`,
						name: p.name,
						icon: p.iconName,
						miniName: p.miniName,
						invisible: !p.visibleInMenu,
						permission: p.permission,
						id:p.id,
						pageOrder:p.pageOrder
					})
				} else if (p.pageType === 'RESOURCE') {
						routes.push({
						path: `/${p.path}`,
						name: p.name,
						icon: p.iconName,
						miniName: p.miniName,
						invisible: !p.visibleInMenu,
						feature: p.path,
						permission: p.permission,
						id:p.id,
						pageOrder:p.pageOrder
						})
				} 
				return routes
			})
			if(pages) {
				state.pages = sortedPages
				state.menus = routes
			}
		})
	},
});

export const { selectPage, setDefaultPage, setIsEditingPage, setHandleUp, setIsReordering, setHandleDown } = pageSlice.actions;
export default pageSlice.reducer;

export const {
	selectById: selectPageById,
	selectIds: selectPageIds,
	selectEntities: selectPageEntities,
	selectAll: selectAllPages,
	selectTotal: selectTotalPages,
} = pageAdapter.getSelectors((state: RootState) => state.page);

export const currentPageId = (state: RootState) => state.page.selectedPage;
export const defaultPage = (state: RootState) => state.page.defaultPage;

export const selectCurrentPageId = createSelector(currentPageId, (id) => id);

export const selectPageByPath = createSelector(
	[(state) => selectAllPages(state), (_, path: string) => path],
	(pages, path) => pages.find((page) => page.path === path),
);
