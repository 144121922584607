import React from 'react';
import { FieldProps } from 'formik';
import { Col, FormGroup, FormGroupProps, Label } from 'reactstrap';
import cn from 'classnames';

export interface FormikFormGroupProps extends FormGroupProps {
	name: string;
	id?: string;
	label?: string;
	required?: boolean;
	caption?: string;
	className?: string;
	hasIcon?:string
}

const FormikFormGroup = (props: FormikFormGroupProps & FieldProps) => {
	const { className, id, name, label, caption, required, field, form, meta, row, children, hasIcon, ...restProps } = props;

	const isFormFieldValid = () => !!(meta.touched && meta.error);

	const getFormErrorMessage = () => {
		return isFormFieldValid() && <small className="p-error">{meta.error}</small>;
	};

	if (row) {
		return (
			<FormGroup className={className} {...restProps} row>
				{label && (
					<Label for={id} className="form-control-label d-flex justify-content-between" md={4}>
						<div>{label} {required && <span className="p-error ml-1">*</span>}</div>
						{hasIcon ? (<i style={{marginRight:'-24px', marginTop:'-7px'}} className={hasIcon}></i>) : null}
					</Label>
				)}
				<Col className={cn({
					'col-md-8': Boolean(label),
					'col-12': !Boolean(label),
				})}>
					{children}
					{getFormErrorMessage()}
					{caption && <small>{caption}</small>}
				</Col>
			</FormGroup>
		);
	}

	return (
		<FormGroup className={className} {...restProps}>
			{label && (
				<Label for={id} className="form-control-label">
					{label}
				</Label>
			)}
			{children}
			{getFormErrorMessage()}
			{caption && <small>{caption}</small>}
		</FormGroup>
	);
};

export default FormikFormGroup;
